import * as React from 'react';
import { useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Button, TextField, Typography, Dialog, DialogTitle, DialogContent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import emailjs from '@emailjs/browser';
import { Facebook, Instagram, LinkedIn } from '@mui/icons-material';

const embedMapStyles = {
    maxWidth: '100%',
    overflow: 'hidden',
    width: '100%',
    height: '100%',
    border: '2px solid #2f2f2f',
};

export default function Form0() {
    const form = useRef();
    const [openDialog, setOpenDialog] = useState(false);
    const { t } = useTranslation();
    const isRTL = i18n.language === 'ar';
    const webinarTitle = "استراتيجيات الانطلاق الناجح للشركات الناشئة - من الفكرة إلى التنفيذ"; // Replace with actual title
    const webinarDate = "السبت الموافق 16 نوفمبر"; // Replace with actual date
    const webinarTime = "الساعة الثامنة مساء"; // Replace with actual time
    const webinarLink = "/workshop.pdf"; // Replace with actual link
    // Function to send confirmation email
    const sendConfirmationEmail = (email, title, date, time, link) => {
        emailjs.send('service_gnauqyb', 'template_ls9js5t', { user_email: email , webinarTitle: title,
          webinarDate: date,
          webinarTime: time,
          webinarLink: link}, {
            publicKey: '-N_px-R9DlxT0_MUs',
        })
        .then(
            () => {
                console.log('Confirmation email sent!');
            },
            (error) => {
                console.log('Failed to send confirmation email...', error.text);
            }
        );
    };

    const sendEmail = (e) => {
      e.preventDefault();
  
      // Ensure form.current is available
      if (!form.current) {
          console.error("Form reference is not available");
          return;
      }
  
      // Use FormData to get form field values
      const formData = new FormData(form.current);
      const userEmail = formData.get('user_email'); // Get the user email from the form data
  
      emailjs.sendForm('service_gnauqyb', 'template_c6p22u8', form.current, {
          publicKey: '-N_px-R9DlxT0_MUs',
      })
      .then(
          () => {
              console.log('SUCCESS!');
              e.target.reset();
              setOpenDialog(true); // Open dialog on success
  
              // Send confirmation email to the user
              sendConfirmationEmail(userEmail, webinarTitle, webinarDate, webinarTime, webinarLink);
          },
          (error) => {
              console.log('FAILED...', error.text);
          }
      );
  };
  

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    return (
        <Box sx={{ flexGrow: 1, width: '100%', padding: '0rem', margin:{xs:'5rem auto',md: '10rem auto'}, direction:isRTL?'rtl':'ltr' }}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <Typography
                        variant="h4"
                        sx={{
                            fontWeight: 'bold',
                            fontSize: { xs: '20px', md: '30px' },
                            color: '#fec62a',
                            fontFamily: isRTL ? 'Noto Kufi Arabic' : 'Poppins',
                            textAlign: 'center',
                            marginBottom: '1rem',
                        }}
                    >
                        {t("Join the event")}
                    </Typography>
                    <Box
                        component="form"
                        ref={form}
                        onSubmit={sendEmail}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '1.5rem',
                            maxWidth: '500px',
                            margin: '0 auto',
                            color: 'white',
                        }}
                    >
                        <TextField
                            sx={{
                                backgroundColor: 'white',
                                '& .MuiOutlinedInput-root fieldset': { borderColor: 'transparent' },
                                '& .MuiInputLabel-root': { color: 'grey', transform: 'translate(16px, 16px) scale(1)' },
                                '& .MuiInputLabel-root.Mui-focused': { color: 'white', transform: 'translate(14px, -18px) scale(0.75)' },
                            }}
                            label="First Name"
                            name="first_name"
                            variant="outlined"
                            fullWidth
                            required
                        />
                        <TextField  sx={{
   backgroundColor: 'white',
   '& .MuiOutlinedInput-root': {
     '& fieldset': {
       borderColor: 'transparent', // No border by default
     },
     '&:hover fieldset': {
       borderColor: 'transparent', // No border on hover
     },
     '&.Mui-focused fieldset': {
       borderColor: 'transparent', // No border on focus
     },
   },
   '& .MuiInputLabel-root': {
     color: 'grey', // Label color by default
     transform: 'translate(16px, 16px) scale(1)', // Position label when not focused
   },
   '& .MuiInputLabel-root.Mui-focused': {
     color: 'white', // Label color when focused
     transform: 'translate(14px, -18px) scale(0.75)', // Adjust label position when focused
   },
 }}
        label="Last Name"
        name="last_name"
        variant="outlined"
        fullWidth
        required
      />
      <TextField sx={{
   backgroundColor: 'white',
   '& .MuiOutlinedInput-root': {
     '& fieldset': {
       borderColor: 'transparent', // No border by default
     },
     '&:hover fieldset': {
       borderColor: 'transparent', // No border on hover
     },
     '&.Mui-focused fieldset': {
       borderColor: 'transparent', // No border on focus
     },
   },
   '& .MuiInputLabel-root': {
     color: 'grey', // Label color by default
     transform: 'translate(16px, 16px) scale(1)', // Position label when not focused
   },
   '& .MuiInputLabel-root.Mui-focused': {
     color: 'white', // Label color when focused
     transform: 'translate(14px, -18px) scale(0.75)', // Adjust label position when focused
   },
 }}
        label="Email"
        name="user_email"
        type="email"
        variant="outlined"
        fullWidth
        required
      />
<TextField sx={{
   backgroundColor: 'white',
   '& .MuiOutlinedInput-root': {
     '& fieldset': {
       borderColor: 'transparent', // No border by default
     },
     '&:hover fieldset': {
       borderColor: 'transparent', // No border on hover
     },
     '&.Mui-focused fieldset': {
       borderColor: 'transparent', // No border on focus
     },
   },
   '& .MuiInputLabel-root': {
     color: 'grey', // Label color by default
     transform: 'translate(16px, 16px) scale(1)', // Position label when not focused
   },
   '& .MuiInputLabel-root.Mui-focused': {
     color: 'white', // Label color when focused
     transform: 'translate(14px, -18px) scale(0.75)', // Adjust label position when focused
   },
 }}
        label="Phone"
        name="user_phone"
        type="tel"
       
        fullWidth
        required
      />
      <TextField sx={{
   backgroundColor: 'white',
   '& .MuiOutlinedInput-root': {
     '& fieldset': {
       borderColor: 'transparent', // No border by default
     },
     '&:hover fieldset': {
       borderColor: 'transparent', // No border on hover
     },
     '&.Mui-focused fieldset': {
       borderColor: 'transparent', // No border on focus
     },
   },
   '& .MuiInputLabel-root': {
     color: 'grey', // Label color by default
     transform: 'translate(16px, 16px) scale(1)', // Position label when not focused
   },
   '& .MuiInputLabel-root.Mui-focused': {
     color: 'white', // Label color when focused
     transform: 'translate(14px, -18px) scale(0.75)', // Adjust label position when focused
   },
 }}
        label="Leave us a Message (not required)"
        name="message"
        multiline
        rows={4}
        variant="outlined"
        fullWidth
        
      />
                        <Button  
                            type="submit"
                            variant="contained"
                            sx={{
                                fontFamily:isRTL?'Noto Kufi Arabic':'Poppins',
                                backgroundColor: '#fec62a',
                                fontSize:'20px',
                                color:'#11111f',
                                '&:hover': { backgroundColor: 'transparent', color: 'white' },
                                padding: '0.6rem 1.2rem',
                                border: '1px solid #fec62a'
                            }}
                        >
                            {t("Register Now")}
                        </Button>
                    </Box>
                </Grid>
            </Grid>

            {/* Success Dialog */}
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle sx={{ textAlign: 'center', fontSize: '24px', fontWeight: 'bold', color: '#fec62a' ,  fontFamily:isRTL?'Noto Kufi Arabic':'Poppins',}}>
                    {t("Success!")}
                </DialogTitle>
                <DialogContent sx={{ textAlign: 'center', padding: '2rem', fontSize: '18px',  fontFamily:isRTL?'Noto Kufi Arabic':'Poppins', }}>
                    {t("You have registered successfully to the workshop.Please check your email!")}
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '1.5rem' }}>
                    
                        <Button
                            onClick={handleCloseDialog}
                            variant="contained"
                            sx={{
                              fontFamily:isRTL?'Noto Kufi Arabic':'Poppins',
                                backgroundColor: '#fec62a',
                                color: '#11111f',
                                '&:hover': { backgroundColor: '#fec62a', color: '#11111f' },
                                padding: '0.6rem 1.2rem',
                                border: '1px solid #fec62a'
                            }}
                        >
                            {t("Close")}
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </Box>
    );
}
