import React from 'react';
import { Card, CardContent, Typography, Box, Container, TextField, Button, Modal } from '@mui/material';
import { styled } from '@mui/material/styles';
import m1 from '../ASSETS/1 (1).png'; 
import m2 from '../ASSETS/2 (1).png'; 
import m3 from '../ASSETS/3 (1).png'; 
import bg1 from '../ASSETS/row-1-column-1.jpg'
import bg2 from '../ASSETS/row-2-column-1.jpg'
import bg3 from '../ASSETS/row-3-column-1.jpg'
import bg4 from '../ASSETS/row-4-column-1.jpg'
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../../Firebase';
import { useEffect } from 'react';
import { useState } from 'react';
import { Edit } from '@mui/icons-material';
import { getTeamFromDB, saveTeamToDB, updateTeamInDB } from '../BlogPage/db';
const data = [
  {
    title: "Leadership",
    answer: "Our leadership team sets the strategic direction of the company, ensuring that we stay at the forefront of industry trends and deliver cutting-edge solutions. With years of experience in various sectors, they guide our operations with a focus on innovation, integrity, and excellence.",
    img: m1,
    bg: bg1,
  },
  {
    title: "Consultants & Advisors",
    answer: "Our consultants and advisors are experts in their respective fields, offering deep insights and practical solutions across a range of business areas. Whether it’s management consulting, finance, HR, IT, or legal services, our team is equipped to handle your most complex challenges.",
    img: m2,
    bg: bg2,
  },
  {
    title: "Support Staff",
    answer: "Behind every successful project is a dedicated support team that ensures everything runs smoothly. From project management to client relations, our support staff plays a crucial role in delivering timely and efficient service.",
    img: m3,
    bg: bg3,
  },
  {
    title: "Collaborative Approach",
    answer: "We believe in the power of collaboration—both within our team and with our clients. By working closely together, we leverage our collective expertise to provide solutions that are not only effective but also aligned with your vision and goals.",
    img: m3,
    bg: bg4,
  },
];
const CardContentStyled = styled(CardContent)(({ theme }) => ({
  position: 'relative',
  transition: 'opacity 0.3s ease',
}));

const AnswerBox = styled(Box)(({ theme }) => ({
  opacity: 0,
  transition: 'opacity 0.3s ease',
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(2),
}));

const CardWrapper = styled(Card)(({ theme, img, bg }) => ({
    position: 'relative',
    overflow: 'hidden',
    transition: 'background-image 0.3s ease',
    borderRadius: '10px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundImage: `url(${bg})`,
    filter: 'brightness(80%)',
    '&:hover': {
      backgroundImage: `url(${img})`,
      color: '#fff',
      cursor: 'pointer',
      '& .content': {
        opacity: 0,
      },
      '& .answer': {
        opacity: 1,
      },
    },
    // Rotate background image on mobile screens
    [theme.breakpoints.down('sm')]: {
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundImage: `url(${bg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        transform: 'rotate(90deg)', // Rotate image by 90 degrees
        transformOrigin: 'center',  // Ensure the rotation is centered
        zIndex: -1, // Ensure it stays in the background
      },
    },
    [theme.breakpoints.down('md')]: {
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundImage: `url(${bg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          transform: 'rotate(90deg)', // Rotate image by 90 degrees
          transformOrigin: 'center',  // Ensure the rotation is centered
          zIndex: -1, // Ensure it stays in the background
        },
      },
  }));
  
  
  

const Team = () => {
  const { t } = useTranslation();
  const isRTL = i18n.language === 'ar';
  const [openModal, setOpenModal] = React.useState(false);
  const [selectedTypography, setSelectedTypography] = React.useState('');

  const [typographyContent0, setTypographyContent0] = useState({
    en: { p1: '',p2: '' },
    ar: { p1: '',p2: '' }
  });

  const [typographyContent, setTypographyContent] = useState({
    en: data.map(card => ({ title: card.title, answer: card.answer })),
    ar: data.map(card => ({ title: card.title, answer: card.answer }))
  });
  
  const [teamId, setTeamId] = useState(null);
  const [pId, setPId] = useState(null);  // Store Firestore document ID
  const [isAdmin, setIsAdmin] = useState(false);  // Track admin status
  
  // Fetch headers from Firestore on mount
  React.useEffect(() => {
    const fetchHow = async () => {
      try {
        const headerData = await getTeamFromDB();
        setPId(headerData[0].id);
        setTeamId(headerData[0].id);

        if (headerData.length > 0) {
          setTypographyContent0({
            en: {
              p1: headerData[0].en.p1 || '',
              p2: headerData[0].en.p2 || '',
            },
            ar: {
              p1: headerData[0].ar.p1 || '',
              p2: headerData[0].ar.p2 || '',
            },
          });
          
          if (headerData[0].en.data || headerData[0].ar.data) {
            setTypographyContent({
              en: headerData[0].en.data || data.map(card => ({ title: card.title, answer: card.answer })),
              ar: headerData[0].ar.data || data.map(card => ({ title: '',answer:'' })),
            });
          } else {
            console.log('No service items found.');
          }
        } else {
          console.log('No header data found.');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      console.log('fetchHeader completed.');
    };

    
    
    fetchHow();
  }, []);
  
  
  

  // Check if user is admin
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user && user.email === 'murouj@work.com') {
        setIsAdmin(true);
      } else {
        setIsAdmin(false);
      }
    });

    return () => unsubscribe();
  }, []);

  // Open modal when edit is clicked
  // Open modal when edit is clicked
  const handleOpenModal = (key, index = null) => {
    if (index !== null) {
      setSelectedTypography(`${key}_${index}`);
    } else {
      setSelectedTypography(key);
    }
    setOpenModal(true);
  };
  
  // Close modal
  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedTypography('');
  };
  
  // Handle form submission to save/update typography in Firestore
  const handleSave = async () => {
    const currentLang = i18n.language;  // Get current language ('en' or 'ar')

    // If editing a service item
    if (selectedTypography.includes('_')) {
      const [key, index] = selectedTypography.split('_');
      const updatedAccItems = [...typographyContent[currentLang]];
      updatedAccItems[index][key] = document.getElementById('typographyInput').value || '';

      const updatedAccItemsContent = {
        ...typographyContent,
        [currentLang]: updatedAccItems,
      };

      setTypographyContent(updatedAccItemsContent);

      try {
        if (teamId) {
          // Update the Firestore document for serviceItems only
          await updateTeamInDB(teamId, {
            [`${currentLang}.data`]: updatedAccItemsContent[currentLang]
          });
        } else {
          // If serviceItems document does not exist, create a new one
          if (teamId) {
            await updateTeamInDB(teamId, {
              [`${currentLang}.data`]: updatedAccItemsContent[currentLang]
            });
          } else {
            const newId = await saveTeamToDB(updatedAccItemsContent);
            setTeamId(newId);
          }
        }
        handleCloseModal();
      } catch (error) {
        console.error('Error saving service items:', error);
      }

    } else {
      // For general typography content
      const updatedContent = {
        ...typographyContent0,
        [currentLang]: {
          ...typographyContent0[currentLang],
          [selectedTypography]: document.getElementById('typographyInput').value || '',
        },
      };

      setTypographyContent0(updatedContent);

      try {
        if (pId) {
          // Update the Firestore document, ensuring only en/ar fields are updated
          await updateTeamInDB(pId, {
            [currentLang]: updatedContent[currentLang]  // Only save the updated language section
          });
        } else {
          // Save a new document if it doesn't exist
          if (pId) {
            await updateTeamInDB(pId, {
              [currentLang]: updatedContent[currentLang]
            });
          } else {
            const newId = await saveTeamToDB(updatedContent);
            setTeamId(newId);
          }
        }
        handleCloseModal();
      } catch (error) {
        console.error('Error saving general content:', error);
      }
    }
  };
  
  

  return (
    <Container sx={{ my: 4, marginTop: '8rem', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
      <Typography sx={{  direction:isRTL?'rtl':'ltr',
            textAlign:isRTL?'right': 'left',
            fontFamily:isRTL?'Noto Kufi Arabic':'Poppins', marginBottom: '5rem', fontWeight: 'bold', color: '#fec62a' ,fontSize: { xs: '30px', md: '50px' },}} variant="h3" gutterBottom align="left">
        {t("Our Team")}
      </Typography>
      <Typography sx={{  direction:isRTL?'rtl':'ltr',
            textAlign:isRTL?'right': 'left',
            fontFamily:isRTL?'Noto Kufi Arabic':'Poppins',marginBottom: '2rem', fontWeight: 'bold', color: 'white',fontSize: { xs: '18px', md: '20px' }, }} variant="h6" gutterBottom align="left">
       {typographyContent0[i18n.language].p1}
         {isAdmin && (
           <Edit onClick={() => handleOpenModal('p1')} sx={{ cursor: 'pointer', marginLeft: 2, color: 'white' }} />
         )}
       </Typography>
      <Typography sx={{  direction:isRTL?'rtl':'ltr',
            textAlign:isRTL?'right': 'left',
            fontFamily:isRTL?'Noto Kufi Arabic':'Poppins', marginBottom: '5rem', fontWeight: 'bold', color: 'white',fontSize: { xs: '18px', md: '20px' }, }} variant="h6" gutterBottom align="left">
       {typographyContent0[i18n.language].p2}
         {isAdmin && (
           <Edit onClick={() => handleOpenModal('p2')} sx={{ cursor: 'pointer', marginLeft: 2, color: 'white' }} />
         )}
       </Typography>
     <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', gap: 1 }}>
  {data.map((item, index) => (
    <Box key={index} sx={{ position: 'relative', width: 280, height: { md: 360, xs: 280 }, '@media (min-width:900px) and (max-width:1400px)': { width: 250 } }}>
      <CardWrapper img={item.img} bg={item.bg} sx={{height:'360px'}}>
        <CardContentStyled className="content">
          <Typography sx={{ direction: isRTL ? 'rtl' : 'ltr', textAlign: isRTL ? 'center' : 'center', fontFamily: isRTL ? 'Noto Kufi Arabic' : 'Poppins', fontWeight: '700', marginTop: { xs: '7rem', md: '9rem' }, color: 'white', fontSize: { xs: '18px', md: '20px' } }} variant="h5">
            {typographyContent[i18n.language][index].title}
          </Typography>
        </CardContentStyled>
        <AnswerBox className="answer">
          <Typography sx={{ direction: isRTL ? 'rtl' : 'ltr', textAlign: isRTL ? 'center' : 'center', fontFamily: isRTL ? 'Noto Kufi Arabic' : 'Poppins', fontWeight: '500', fontSize: { xs: '14px', md: '18px' }, '@media (min-width:900px) and (max-width:1400px)': { fontSize: '15px' } }} variant="body1" align="center">
            {typographyContent[i18n.language][index].answer}
          </Typography>
        </AnswerBox>
      </CardWrapper>

      {/* Edit icons outside the Card */}
      {isAdmin && (
        <Box sx={{ position: 'absolute', top: 0, right: 0, display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Edit
            onClick={() => handleOpenModal('title', index)}
            sx={{ cursor: 'pointer', color: 'white' }}
          ></Edit>
          <Edit
            onClick={() => handleOpenModal('answer', index)}
            sx={{ cursor: 'pointer', color: 'white' }}
          />
        </Box>
      )}
    </Box>
  ))}
</Box>
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
            width: '80%',
          }}
        >
          <Typography variant="h6" component="h2">
            Edit Content
          </Typography>
          <TextField
            id="typographyInput"
            defaultValue={
              selectedTypography.includes('_')
                ? typographyContent[i18n.language][selectedTypography.split('_')[1]][selectedTypography.split('_')[0]]
                : typographyContent0[i18n.language][selectedTypography]
            }
            fullWidth
            margin="normal"
          />
          <Button onClick={handleSave} variant="contained" color="primary">
            Save
          </Button>
        </Box>
      </Modal>
    </Container>
  );
};

export default Team;
