import React from 'react';
import { Box, Breadcrumbs, Button, Grid, Typography } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { AccessTime, CalendarMonth, Email, Event, Facebook, Instagram, LinkedIn, LockClock, NavigateBefore, NavigateNext, WhatsApp } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import Form from '../ContactPage/Form';
import Form0 from './Form0';


const Body = ({headerImg}) => {
  const { t } = useTranslation();
  const isRTL = i18n.language === 'ar';
  
  return (
    <Box sx={{margin:{xs:'0rem 1rem 0rem 1rem',md:'6rem 4rem 2rem 0rem','@media (min-width: 900px) and (max-width: 1300px)': {
             margin:'6rem 2rem 2rem 2rem'
            },}}}>
         
        <Grid container spacing={2} columns={16}>
  <Grid item xs={16} md={8} sx={{order:isRTL?2:1}}>
 <Form0></Form0>
 
<Box>
 <Box
            sx={{
              display: 'flex',
              justifyContent: { xs: 'center', md: 'center' },
              gap:{xs:'1rem',md: '2rem'},
              fontSize: { xs: '1.5rem', md: '2rem' },
              marginTop:{xs:'-3rem',md: '-7rem'},
              marginRight:{xs:'0rem',md:'2rem'},
              marginBottom:'2rem'
            }}
          >
            
            <a style={{ cursor: 'pointer', textDecoration: 'none' }} href="https://www.linkedin.com/uas/login?session_redirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fmurouj-business-solutions%2Fabout%2F%3FviewAsMember%3Dtrue">
              <LinkedIn sx={{ color: '#fec62a', fontSize: { xs: '1.5rem', md: '2rem' } }} />
            </a>
            <a style={{ cursor: 'pointer', textDecoration: 'none' }} href="https://www.instagram.com/muroujbusiness?igsh=MWhva3JlY3c5M3o0Nw%3D%3D&amp%3Butm_source=qr">
              <Instagram sx={{ color: '#fec62a', fontSize: { xs: '1.5rem', md: '2rem' } }} />
            </a>
           
            <a
              style={{ cursor: 'pointer', textDecoration: 'none' }}
              href="https://www.facebook.com/profile.php?id=61565302116134&amp%3Bsk=mentions&amp%3Bviewas=100000686899395" 
            >
              <Facebook sx={{ color: '#fec62a', fontSize: { xs: '1.5rem', md: '2rem' } }} />
            </a>
            <a
              style={{ cursor: 'pointer', textDecoration: 'none' }}
              href="https://wa.link/mjavdz" 
            >
              <WhatsApp sx={{ color: '#fec62a', fontSize: { xs: '1.5rem', md: '2rem' } }} />
            </a>
            <a
              style={{ cursor: 'pointer', textDecoration: 'none' }}
              href="mailto:info@murouj-businesssolutions.com" 
            >
              <Email sx={{ color: '#fec62a', fontSize: { xs: '1.5rem', md: '2rem' } }} />
            </a>
            
          </Box>
          </Box>
   <Typography sx={{color:'white',fontSize:'20px',  fontFamily:isRTL?'Noto Kufi Arabic':'Poppins',   direction :isRTL ? 'rtl' : 'ltr',       marginTop:'4rem',marginBottom:'2rem'}}> {t("The Workshop Details PDF")}</Typography>
        
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
              <Button 
                variant="contained" 
                 
                href="/Workshop-contents.pdf" 
                download
                sx={{ padding: '1rem 2rem', fontSize: '1.2rem',backgroundColor:'#fec62a',color:'#11111f',fontWeight:'bold',  fontFamily:isRTL?'Noto Kufi Arabic':'Poppins',    direction :isRTL ? 'rtl' : 'ltr',    marginBottom:'4rem' ,  '&:hover': { backgroundColor: 'transparent', color: 'white',border:'1px solid #fec62a' },  }}
              >
                {t("Download PDF")}
              </Button>
              </Box>
    </Grid>
    <Grid sx={{order:isRTL?2:1}}item xs={16} md={8}>
    <Box>
   <Typography
          variant="h4"
          sx={{
            lineHeight:1.4,
            color: '#11111f',
            fontWeight: 'bold',
            fontSize: { xs: '22px', md: '30px' },
            fontFamily:isRTL?'Noto Kufi Arabic':'Poppins',
            marginBottom:{xs:'2rem',md: '5rem'},
            marginLeft:'0rem',
            backgroundColor:'white',
            display: 'inline-block',  // This will make the background color fit the text width
           padding:'1rem 1rem',
            borderRadius:'10px'
            
          }}
        >
{t("Workshop Explanation")}
</Typography></Box>
<Box>
<Typography
          variant="h4"
          sx={{
            lineHeight:1.4,
            color: '#fec62a',
            fontWeight: 'bold',
            fontSize: { xs: '22px', md: '30px' },
            fontFamily:isRTL?'Noto Kufi Arabic':'Poppins',
            marginBottom: '1rem',
            marginRight:isRTL?'3rem':'0rem',
            marginLeft:isRTL?'0rem':'3rem',
            textAlign:isRTL?'right':'left',
            backgroundColor:'#11111f',
              // This will make the background color fit the text width
           
            borderRadius:'10px'
            
          }}
        >
{t("Workshop Subject:")}
</Typography >

<Typography variant="h6"
          sx={{
            lineHeight:1.4,
            color: 'white',
            fontWeight: 'bold',
            fontSize: { xs: '16px', md: '20px' },
            fontFamily:isRTL?'Noto Kufi Arabic':'Poppins',
            marginBottom: '1rem',
            marginRight:isRTL?'3rem':'0rem',
            marginLeft:isRTL?'0rem':'3rem',
            textAlign:isRTL?'right':'left',
            backgroundColor:'#11111f',
            direction :isRTL ? 'rtl' : 'ltr',            // This will make the background color fit the text width
           
            borderRadius:'10px'
            
          }}>{t("If you have an idea for a startup and want to turn it into a successful company, or if you have an existing startup and are looking for ways to expand and grow, then this workshop is designed specifically for you.")}</Typography>
<Typography variant="h6"
          sx={{
            lineHeight:1.4,
            color: 'white',
            fontWeight: 'bold',
            fontSize: { xs: '16px', md: '20px' },
            fontFamily:isRTL?'Noto Kufi Arabic':'Poppins',
            marginBottom: '1rem',
            marginRight:isRTL?'3rem':'0rem',
            marginLeft:isRTL?'0rem':'3rem',
            textAlign:isRTL?'right':'left',
            backgroundColor:'#11111f',
            direction :isRTL ? 'rtl' : 'ltr',    
            // This will make the background color fit the text width
           
            borderRadius:'10px'
            
          }}>{t("This educational seminar will take you through the journey of founding and launching a successful startup from scratch. During this session, we will discuss the fundamental steps to build a successful project, starting from market analysis and identifying opportunities, to creating a clear strategic plan, and building strong relationships with customers.")}</Typography>

</Box>
  
<Box>
<Typography
          variant="h4"
          sx={{
            lineHeight:1.4,
            color: '#fec62a',
            fontWeight: 'bold',
            fontSize: { xs: '22px', md: '30px' },
            fontFamily:isRTL?'Noto Kufi Arabic':'Poppins',
            marginBottom: '1rem',
          
            backgroundColor:'#11111f',
            // This will make the background color fit the text width
            marginRight:isRTL?'3rem':'0rem',
            marginLeft:isRTL?'0rem':'3rem',
            textAlign:isRTL?'right':'left',
            borderRadius:'10px'
            
          }}
        >
{t("Workshop Objectives:")}
</Typography>
<Typography variant="h6"
          sx={{
            lineHeight:1.4,
            color: 'white',
            fontWeight: 'bold',
            fontSize: { xs: '16px', md: '20px' },
            fontFamily:isRTL?'Noto Kufi Arabic':'Poppins',
            marginBottom: '1rem',
            marginRight:isRTL?'3rem':'0rem',
            marginLeft:isRTL?'0rem':'3rem',
            textAlign:isRTL?'right':'left',
            backgroundColor:'#11111f',
            direction :isRTL ? 'rtl' : 'ltr',   

            // This will make the background color fit the text width
           
            borderRadius:'10px'
            
          }}>{t("Exploring Opportunities and Market Assessment: Understanding how to assess the market environment and discover suitable opportunities for your startup project.")}</Typography>
          <Typography variant="h6"
          sx={{
            lineHeight:1.4,
            color: 'white',
            fontWeight: 'bold',
            fontSize: { xs: '16px', md: '20px' },
            fontFamily:isRTL?'Noto Kufi Arabic':'Poppins',
            marginBottom: '1rem',
            marginRight:isRTL?'3rem':'0rem',
            marginLeft:isRTL?'0rem':'3rem',
            textAlign:isRTL?'right':'left',
            backgroundColor:'#11111f',
            direction :isRTL ? 'rtl' : 'ltr',   

            // This will make the background color fit the text width
           
            borderRadius:'10px'
            
          }}>{t("Strategic Planning and Goal Setting: Learning how to formulate a strategic plan that sets pivotal goals and supports growth stages.")}</Typography>
          <Typography variant="h6"
          sx={{
            lineHeight:1.4,
            color: 'white',
            fontWeight: 'bold',
            fontSize: { xs: '16px', md: '20px' },
            fontFamily:isRTL?'Noto Kufi Arabic':'Poppins',
            marginBottom: '1rem',
            marginRight:isRTL?'3rem':'0rem',
            marginLeft:isRTL?'0rem':'3rem',
            textAlign:isRTL?'right':'left',
            backgroundColor:'#11111f',
            direction :isRTL ? 'rtl' : 'ltr',   

            // This will make the background color fit the text width
           
            borderRadius:'10px'
            
          }}>{t("Effective Resource Management: Methods to invest available financial and human resources in a way that ensures sustainability and expansion.")}</Typography>
          
          <Typography variant="h6"
          sx={{
            lineHeight:1.4,
            color: 'white',
            fontWeight: 'bold',
            fontSize: { xs: '16px', md: '20px' },
            fontFamily:isRTL?'Noto Kufi Arabic':'Poppins',
            marginBottom: '1rem',
            marginRight:isRTL?'3rem':'0rem',
            marginLeft:isRTL?'0rem':'3rem',
            textAlign:isRTL?'right':'left',
            backgroundColor:'#11111f',
            direction :isRTL ? 'rtl' : 'ltr',   

            // This will make the background color fit the text width
           
            borderRadius:'10px'
            
          }}>{t("Building Strong Customer Relationships: Developing strategies focused on attracting and retaining customers' loyalty.")}</Typography>
          
          </Box>
          <Box>
<Typography
          variant="h4"
          sx={{
            lineHeight:1.4,
            color: '#fec62a',
            fontWeight: 'bold',
            fontSize: { xs: '22px', md: '30px' },
            fontFamily:isRTL?'Noto Kufi Arabic':'Poppins',
            marginBottom: '1rem',
          
            backgroundColor:'#11111f',
              // This will make the background color fit the text width
              marginRight:isRTL?'3rem':'0rem',
              marginLeft:isRTL?'0rem':'3rem',
              textAlign:isRTL?'right':'left',
            borderRadius:'10px'
            
          }}
        >
{t("Who should attend this workshop?")}
</Typography>
<Typography variant="h6"
          sx={{
            lineHeight:1.4,
            color: 'white',
            fontWeight: 'bold',
            fontSize: { xs: '16px', md: '20px' },
            fontFamily:isRTL?'Noto Kufi Arabic':'Poppins',
            marginBottom: '1rem',
            marginRight:isRTL?'3rem':'0rem',
            marginLeft:isRTL?'0rem':'3rem',
            textAlign:isRTL?'right':'left',
            backgroundColor:'#11111f',
            direction :isRTL ? 'rtl' : 'ltr',    

            // This will make the background color fit the text width
           
            borderRadius:'10px'
            
          }}>{t("Beginner Entrepreneurs: Those who have ideas and want to turn them into real projects, and need guidance to build a strong foundation from the start.")}</Typography>
          <Typography variant="h6"
          sx={{
            lineHeight:1.4,
            color: 'white',
            fontWeight: 'bold',
            fontSize: { xs: '16px', md: '20px' },
            fontFamily:isRTL?'Noto Kufi Arabic':'Poppins',
            marginBottom: '1rem',
            marginRight:isRTL?'3rem':'0rem',
            marginLeft:isRTL?'0rem':'3rem',
            textAlign:isRTL?'right':'left',
            backgroundColor:'#11111f',
            direction :isRTL ? 'rtl' : 'ltr',    

            // This will make the background color fit the text width
           
            borderRadius:'10px'
            
          }}>{t("Startup Owners: Those looking for effective ways to expand and grow, and to overcome the daily challenges they face in managing their businesses.")}</Typography>
          <Typography variant="h6"
          sx={{
            lineHeight:1.4,
            color: 'white',
            fontWeight: 'bold',
            fontSize: { xs: '16px', md: '20px' },
            fontFamily:isRTL?'Noto Kufi Arabic':'Poppins',
            marginBottom: '1rem',
            marginRight:isRTL?'3rem':'0rem',
            marginLeft:isRTL?'0rem':'3rem',
            textAlign:isRTL?'right':'left',
            backgroundColor:'#11111f',
            direction :isRTL ? 'rtl' : 'ltr',    

            // This will make the background color fit the text width
           
            borderRadius:'10px'
            
          }}>{t("Investors and Venture Capitalists: Those who wish to understand growth strategies and evaluate investment opportunities in promising startups.")}</Typography>

<Typography variant="h6"
          sx={{
            lineHeight:1.4,
            color: 'white',
            fontWeight: 'bold',
            fontSize: { xs: '16px', md: '20px' },
            fontFamily:isRTL?'Noto Kufi Arabic':'Poppins',
            marginBottom: '1rem',
            marginRight:isRTL?'3rem':'0rem',
            marginLeft:isRTL?'0rem':'3rem',
            textAlign:isRTL?'right':'left',
            backgroundColor:'#11111f',
            direction :isRTL ? 'rtl' : 'ltr',    

            // This will make the background color fit the text width
           
            borderRadius:'10px'
            
          }}>{t("Consultants and Trainers in Entrepreneurship: Those who want to enrich their knowledge with the latest tools and strategies to better assist their clients.")}</Typography>



</Box>
<Box>
<Typography
          variant="h4"
          sx={{
            lineHeight:1.4,
            color: '#fec62a',
            fontWeight: 'bold',
            fontSize: { xs: '22px', md: '30px' },
            fontFamily:isRTL?'Noto Kufi Arabic':'Poppins',
            marginBottom: '1rem',
           
            backgroundColor:'#11111f',
             // This will make the background color fit the text width
             marginRight:isRTL?'3rem':'0rem',
             marginLeft:isRTL?'0rem':'3rem',
             textAlign:isRTL?'right':'left',
            borderRadius:'10px'
            
          }}
        >
{t("Who is Luay Abdullah?")}
</Typography>
{/* <Typography variant="h6"
          sx={{
            lineHeight:1.4,
            color: 'white',
            fontWeight: 'bold',
            fontSize: { xs: '16px', md: '20px' },
            fontFamily:isRTL?'Noto Kufi Arabic':'Poppins',
            marginBottom: '1rem',
            marginRight:isRTL?'3rem':'0rem',
            marginLeft:isRTL?'0rem':'3rem',
            textAlign:isRTL?'right':'left',
            backgroundColor:'#11111f',
            // This will make the background color fit the text width
           
            borderRadius:'10px'
            
          }}>{t("An international lecturer and certified trainer in business management, development, and human resources.")}</Typography> */}
           <Typography variant="h6"
          sx={{
            lineHeight:1.4,
            color: 'white',
            fontWeight: 'bold',
            fontSize: { xs: '16px', md: '20px' },
            fontFamily:isRTL?'Noto Kufi Arabic':'Poppins',
            marginBottom: '1rem',
            marginRight:isRTL?'3rem':'0rem',
            marginLeft:isRTL?'0rem':'3rem',
            textAlign:isRTL?'right':'left',
            backgroundColor:'#11111f',
            direction :isRTL ? 'rtl' : 'ltr',   
            // This will make the background color fit the text width
           
            borderRadius:'10px'
            
          }}>{t("Lecturer Louay Abdullah is an internationally certified consultant and trainer since 2003 in the fields of business management and development, human resources, project management, intellectual property, and brand building.")}</Typography>
          <Typography variant="h6"
          sx={{
            lineHeight:1.4,
            color: 'white',
            fontWeight: 'bold',
            fontSize: { xs: '16px', md: '20px' },
            fontFamily:isRTL?'Noto Kufi Arabic':'Poppins',
            marginBottom: '1rem',
            marginRight:isRTL?'3rem':'0rem',
            marginLeft:isRTL?'0rem':'3rem',
            textAlign:isRTL?'right':'left',
            backgroundColor:'#11111f',
            direction :isRTL ? 'rtl' : 'ltr',   
            // This will make the background color fit the text width
           
            borderRadius:'10px'
            
          }}>{t("He works as an international lecturer in various prestigious management disciplines, certified by major educational institutions such as Cairo University and the American Institute for Professional Studies in the United States.")}</Typography>
          <Typography variant="h6"
          sx={{
            lineHeight:1.4,
            color: 'white',
            fontWeight: 'bold',
            fontSize: { xs: '16px', md: '20px' },
            fontFamily:isRTL?'Noto Kufi Arabic':'Poppins',
            marginBottom: '1rem',
            marginRight:isRTL?'3rem':'0rem',
            marginLeft:isRTL?'0rem':'3rem',
            textAlign:isRTL?'right':'left',
            backgroundColor:'#11111f',
            direction :isRTL ? 'rtl' : 'ltr',   
            // This will make the background color fit the text width
           
            borderRadius:'10px'
            
          }}>{t("He has over 20 years of experience providing consulting and training services to major ministries and companies in the Arab world. He offers consulting services to various companies such as Murooj Business Solutions in Kuwait, Ibkar Management Consulting in Riyadh, MCM Consultancy in Jeddah, and Value Platform and Mastery Academy in Malaysia. He holds a professional doctorate in business administration specializing in human resource management, along with numerous professional certifications such as HRM, IPS, OD, MBA, and PMP.")}</Typography>
          
          
         
</Box>
  </Grid>
    </Grid>
   </Box> 
  );
};

export default Body;
