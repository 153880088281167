import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Box, Button, Card, CardContent, Grid, Modal, TextField } from '@mui/material';
import a from '../ASSETS/young-employee-with-glasses-paying-attention.jpg';
import img1 from '../ASSETS/rating.png'
import img2 from '../ASSETS/advance-technology.png'
import img3 from '../ASSETS/solutions.png'
import img4 from '../ASSETS/quality-assurance.png'
import img5 from '../ASSETS/secure.png'
import img6 from '../ASSETS/communication.png'
/* import { useTranslation } from 'react-i18next'; */
import {motion} from 'framer-motion'
import i18n from '../../i18n';
import { useTranslation } from 'react-i18next';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../../Firebase';
import { useEffect } from 'react';
import { useState } from 'react';
import { Edit } from '@mui/icons-material';
import { getChooseFromDB, saveChooseToDB, updateChooseInDB } from '../BlogPage/db';
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  border: '1px solid black',
  borderRadius: 10,
  marginBottom: 16,
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  '&:before': {
    display: 'none',
  },
  '&:first-of-type': {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  '&:last-of-type': {
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(() => ({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: 8,
    fontFamily: 'Raleway',
    fontWeight: 'bold',
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  padding: 16,
  borderTop: '1px solid rgba(0, 0, 0, 0.125)',
  fontFamily: 'Raleway',
}));

const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: 1500,
  padding: theme.spacing(3),
  background: 'linear-gradient(135deg, #f5f7fa, #D3CEC8)', // Gradient background color
  borderRadius: '20px',
  boxShadow: theme.shadows[3],
  flex: '1 1 45%',
  marginBottom: '0rem',
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
  },
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
}));

const Image = styled('img')({
  width: '70%',
  height: 'auto',
  borderRadius: 8,
});

const accordionData = [
  {
    icon: img1,
    title: 'Expertise',
    content: 'Our team’s deep BPO experience enables us to provide specialized solutions tailored to unique business needs.',
  },
  {
    icon: img2,
    title: 'Cutting-Edge Technology',
    content: 'We use cutting-edge technology, such as automation, AI, and data analytics, to improve processes and boost productivity.',
  },
  {
    icon: img3,
    title: 'Tailored Solutions',
    content: 'We tailor our solutions to each client`s specific needs and goals to ensure maximum value and ROI.',
  },
  {
    icon: img4,
    title: 'Quality Assurance',
    content: 'We focus on quality through strict control measures and continuous improvement to provide error-free and efficient services.',
  },
  {
    icon: img5,
    title: 'Data Security',
    content: 'We prioritize data security and confidentiality with strict protocols and compliance with industry standards.',
  },
  {
    icon: img6,
    title: 'Transparent Communication',
    content: 'We ensure open communication, keeping clients informed and involved throughout the process.',
  },
  
];

export default function WhyChooseUs() {
  const { t} = useTranslation();
  const isRTL = i18n.language === 'ar'; 
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const [openModal, setOpenModal] = React.useState(false);
  const [selectedTypography, setSelectedTypography] = React.useState('');

  const [typographyContent0, setTypographyContent0] = useState({
    en: { p1: ''  },
    ar: { p1: ''}
  });
  const [typographyContent, setTypographyContent] = useState({
    en: accordionData.map(card => ({ title: card.title, content: card.content })),
    ar: accordionData.map(card => ({ title: card.title, content:card.content }))
  });
  const [pId, setPId] = useState(null);
  const [chooseId, setChooseId] = useState(null);  // Store Firestore document ID
  const [isAdmin, setIsAdmin] = useState(false);  // Track admin status
  
  // Fetch headers from Firestore on mount
  React.useEffect(() => {
    const fetchHow = async () => {
      try {
        const howData = await getChooseFromDB();

        setPId(howData[0].id);
        setChooseId(howData[0].id);

        if (howData.length > 0) {
          setTypographyContent0({
            en: {
              p1: howData[0].en.p1 || '',
             
            },
            ar: {
              p1: howData[0].ar.p1 || '',
            
            },
          });
          
          if (howData[0].en.accordionData || howData[0].ar.accordionData) {
            setTypographyContent({
              en: howData[0].en.accordionData || accordionData.map(card => ({ title: card.title,content: card.content })),
              ar: howData[0].ar.accordionData || accordionData.map(card => ({ title: '',content: ''  })),
            });
          } else {
            console.log('No service items found.');
          }
        } else {
          console.log('No header data found.');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      console.log('fetchHeader completed.');
    };

    fetchHow();
  }, []);




  // Check if user is admin
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user && user.email === 'murouj@work.com') {
        setIsAdmin(true);
      } else {
        setIsAdmin(false);
      }
    });

    return () => unsubscribe();
  }, []);

  // Open modal when edit is clicked
  // Open modal when edit is clicked
  const handleOpenModal = (key, index = null) => {
    if (index !== null) {
      setSelectedTypography(`${key}_${index}`);
    } else {
      setSelectedTypography(key);
    }
    setOpenModal(true);
  };

  // Close modal
  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedTypography('');
  };

  const handleOpenHeaderModal = () => {
    setSelectedTypography({ key: 'header' });
    setOpenModal(true);
  };
  // Handle form submission to save/update typography in Firestore
   const handleSave = async () => {
    const currentLang = i18n.language;  // Get current language ('en' or 'ar')

    // If editing a service item
    if (selectedTypography.includes('_')) {
      const [key, index] = selectedTypography.split('_');
      const updatedAccItems = [...typographyContent[currentLang]];
      updatedAccItems[index][key] = document.getElementById('typographyInput').value || '';

      const updatedAccItemsContent = {
        ...typographyContent,
        [currentLang]: updatedAccItems,
      };

      setTypographyContent(updatedAccItemsContent);

      try {
        if (chooseId) {
          // Update the Firestore document for serviceItems only
          await updateChooseInDB(chooseId, {
            [`${currentLang}.accordionData`]: updatedAccItemsContent[currentLang]
          });
        } else {
          // If serviceItems document does not exist, create a new one
          if (chooseId) {
            await updateChooseInDB(chooseId, {
              [`${currentLang}.accordionData`]: updatedAccItemsContent[currentLang]
            });
          } else {
            const newId = await saveChooseToDB(updatedAccItemsContent);
            setChooseId(newId);
          }
        }
        handleCloseModal();
      } catch (error) {
        console.error('Error saving service items:', error);
      }

    } else {
      // For general typography content
      const updatedContent = {
        ...typographyContent0,
        [currentLang]: {
          ...typographyContent0[currentLang],
          [selectedTypography]: document.getElementById('typographyInput').value || '',
        },
      };

      setTypographyContent0(updatedContent);

      try {
        if (pId) {
          // Update the Firestore document, ensuring only en/ar fields are updated
          await updateChooseInDB(pId, {
            [currentLang]: updatedContent[currentLang]  // Only save the updated language section
          });
        } else {
          // Save a new document if it doesn't exist
          if (pId) {
            await updateChooseInDB(pId, {
              [currentLang]: updatedContent[currentLang]
            });
          } else {
            const newId = await saveChooseToDB(updatedContent);
            setChooseId(newId);
          }
        }
        handleCloseModal();
      } catch (error) {
        console.error('Error saving general content:', error);
      }
    }
  };

  
  
  return (
   
       
      <Grid sx={{ direction:isRTL?'rtl':'ltr',}} container spacing={4} alignItems="center">
        <Grid item xs={12} md={6} sx={{ order: { xs: 2, md: 2 } }}>
          <Box sx={{marginTop:{md:'12rem',xs:'2rem'}, marginLeft: { xs: '1rem', md: '-2rem' }, marginRight: { xs: '1rem', md: '2rem' } ,direction:isRTL?'rtl':'ltr' }}>
            {accordionData.map((item, index) => (
               <motion.div
               key={index}
               initial={{ x: -100, opacity: 0 }}
               whileInView={{ x: 0, opacity: 1 }}
               transition={{
                 duration: 2,
                 ease: 'easeInOut',
                 type: 'spring',
                 stiffness: 70,
                 delay: index * 0.1,
               }}
               viewport={{ once: true }}
               sx={{
                 borderRadius: '15px',
                 maxWidth: { xs: 400, md: 300 },
                 width: '100%',
                 alignItems: 'center',
                 justifyContent: 'center',
                 textAlign: 'center',
                 position: 'relative',
                 overflow: 'hidden',
               }}
             >
              <Card key={item} sx={{ maxWidth: 600, width: '100%', boxShadow: 3, backgroundColor: 'white',marginBottom:'1rem', }}>
              <CardContent sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <img src={item.icon} style={{ width: '18%' }} />
                <Box>
                  <Typography sx={{direction:isRTL?'rtl':'ltr',
            textAlign:isRTL?'right': 'left',
            fontFamily:isRTL?'Noto Kufi Arabic':'Poppins',color:'#11111f',fontWeight:'bold',fontSize:{xs:'',md:isRTL?'18px':'20px'}}} variant="h6" gutterBottom>
                    {typographyContent[i18n.language][index].title}
                        {isAdmin && (
                          <Edit
                            onClick={() => handleOpenModal('title', index)}
                            sx={{ cursor: 'pointer', color: 'purple', marginLeft: '1rem' }}
                          />
  )}
                  </Typography>
                  <Typography sx={{direction:isRTL?'rtl':'ltr',
            textAlign:isRTL?'right': 'left',
            fontFamily:isRTL?'Noto Kufi Arabic':'Poppins',color:'#11111f',marginTop:'-0.4rem',fontSize:{xs:'',md:isRTL?'15px':'19px'}}} variant="body1">
                  {typographyContent[i18n.language][index].content}
                        {isAdmin && (
                          <Edit
                            onClick={() => handleOpenModal('content', index)}
                            sx={{ cursor: 'pointer', color: 'purple', marginLeft: '1rem' }}
                          />
  )}
                  </Typography>
                </Box>
              </CardContent>
            </Card></motion.div>
            ))}
          </Box>
        </Grid>
        <Grid  item xs={12} md={6} sx={{ order: { xs: 1, md: 1 } }}>
          <Box sx={{marginRight:{md:'8rem',xs:'0rem'},}}>
        <Box
     
      
     > <motion.div
     initial={{ x: -100, opacity: 0 }}
     whileInView={{ x: 0, opacity: 1 }}
     transition={{ duration: 1.2, ease: 'easeOut' }}
     viewport={{ once: true }}
   >
       <Typography
         variant="h3"
         sx={{
           fontWeight: '600',
           fontSize: { xs: '30px', md: '40px' }, // Responsive font size
           direction:isRTL?'rtl':'ltr',
           textAlign:isRTL?'right': 'left',
           fontFamily:isRTL?'Noto Kufi Arabic':'Poppins',
           marginTop:'4rem',
           
           margin:{xs:'3rem 2rem',md:'10rem 2rem -2rem 11rem'},
           color:'#fec62a',
           '@media (min-width:900px) and (max-width:1400px)': {
           
            margin: '3rem -5rem 3rem 9rem',
           fontSize:'38px'
          },
         }}
       > {t("Why Choose US ?")}
            </Typography></motion.div>
            <motion.div
        initial={{ x: -100, opacity: 0 }}
        whileInView={{ x: 0, opacity: 1 }}
        transition={{ duration: 1.2, ease: 'easeOut' }}
        viewport={{ once: true }}
      > <Typography variant="h3"
         sx={{
             margin:{xs:'3rem 2rem',md:isRTL?'6rem 2rem 2rem 0rem':'6rem 0rem 2rem 11rem'},
             direction:isRTL?'rtl':'ltr',
            textAlign:isRTL?'right': 'left',
            fontFamily:isRTL?'Noto Kufi Arabic':'Poppins',
             color:'white',
           fontWeight: '400',
           fontSize: { xs: '15px', md: '17px' },
          
           lineHeight:1.4,'@media (min-width:900px) and (max-width:1400px)': {
           
            margin: '3rem -5rem 3rem 9rem',
           fontSize:'17px'
          },// Responsive font size
          
           
        
         }}> {typographyContent0[i18n.language].p1}
         {isAdmin && (
           <Edit onClick={() => handleOpenModal('p1')} sx={{ cursor: 'pointer', marginLeft: 2, color: 'white' }} />
         )}
       </Typography>
</motion.div>
     
     
       
     </Box>
  <Box 
    sx={{ 
      position: 'relative', // Make the container relative
      marginLeft: { md: '0rem', sm: '10rem', xs: '0rem' },
      marginRight:{xs:'0rem' ,md:'2rem'} ,
     right:{md:'0%',xs:'12%'},
     left:{md:'13%',xs:'0%'},
    }}
  >
    <motion.div
     initial={{ x: -100, opacity: 0 }}
     whileInView={{ x: 0, opacity: 1 }}
     transition={{ duration: 1.2, ease: 'easeOut' }}
     viewport={{ once: true }}
   >
    <Box sx={{width:{xs: '80%',md:'90%'}}}>
    <Image 
      src={a} 
      style={{
        width:'100%', 

        marginRight: '0rem',
        marginLeft:'5rem','@media (min-width:900px) and (max-width:1400px)': {
           
          marginLeft:'3rem'
          },
        filter: 'brightness(100%)', // Darken the image
      }} 
    /></Box>
    </motion.div>
  </Box></Box>
</Grid>
<Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
            width: '80%',
          }}
        >
          <Typography variant="h6" component="h2">
            Edit Content
          </Typography>
          <TextField
            id="typographyInput"
            defaultValue={
              selectedTypography.includes('_')
                ? typographyContent[i18n.language][selectedTypography.split('_')[1]][selectedTypography.split('_')[0]]
                : typographyContent0[i18n.language][selectedTypography]
            }
            fullWidth
            margin="normal"
          />
          <Button onClick={handleSave} variant="contained" color="primary">
            Save
          </Button>
        </Box>
      </Modal>
      </Grid>
    
  );
}
