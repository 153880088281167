
import About from "../ABOUT/About";
import Blog from "../BlogPage/Blog";
import Footer from "../FOOTER/Footer";
import Header from "../Webpages/Header";
import HeaderPages from "../HeaderPages";
import HowitWorks from "../HowitWorks/HowitWorks";
import Navbar from "../NAVBAR/Navbar";
import { header10, header11, header2 } from "../SERVICES/HeaderImgs";
import Services from "../SERVICES/Services";
import Body from "../Webpages/Body";
import CountdownToWebinar from "../Webpages/CountDown";

function WebPage() {
 
    return (
      <div className="App">
        <Navbar></Navbar>
       
       {/*  <HeaderPages headerImg={header11}></HeaderPages> */}
      <Header headerImg={header11}></Header>
      <CountdownToWebinar></CountdownToWebinar>
      <Body></Body>

        <Footer></Footer>
       
  
      </div>
    );
  }
  
  export default WebPage;
  