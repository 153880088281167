import { Email, LocationOn, Phone, WhatsApp } from "@mui/icons-material";
import img1 from '../ASSETS/planning.png'
import img9 from '../ASSETS/insurance.png'
import img2 from '../ASSETS/high-performance.png'
import img3 from '../ASSETS/financial-advisor.png'
import img4 from '../ASSETS/feasibility.png'
import img5 from '../ASSETS/efficiency.png'
import img6 from '../ASSETS/risk-assessment.png'
import img7 from '../ASSETS/change-management.png'
import img8 from '../ASSETS/sales-performance.png'
import img10 from '../ASSETS/planning.png'
import img11 from '../ASSETS/audit.png'
import img12 from '../ASSETS/bookkeeping.png'
import img13 from '../ASSETS/cash-flow.png'
import img14 from '../ASSETS/budget (1).png'
import img15 from '../ASSETS/budget (2).png'
import img16 from '../ASSETS/budget (3).png'
import img17 from '../ASSETS/audio.png'
import img18 from '../ASSETS/accounting (2).png'
import img19 from '../ASSETS/acquisition.png'
import img20 from '../ASSETS/global.png'
import img21 from '../ASSETS/self-development.png'
import img22 from '../ASSETS/wages.png'
import img23 from '../ASSETS/policy.png'
import img24 from '../ASSETS/startup.png'

import img25 from '../ASSETS/presentation (2).png'
import img26 from '../ASSETS/compliance (2).png'
import img27 from '../ASSETS/approved.png'
import img28 from '../ASSETS/management (1).png'
import img29 from '../ASSETS/political.png'
import img30 from '../ASSETS/operator.png'
import img31 from '../ASSETS/owner.png'

import img43 from '../ASSETS/system-setting.png'
import img32 from '../ASSETS/web-protection.png'
import img33 from '../ASSETS/server.png'
import img34 from '../ASSETS/testing.png'
import img35 from '../ASSETS/consultant.png'

import img36 from '../ASSETS/personal.png'
import img37 from '../ASSETS/company-vision.png'
import img38 from '../ASSETS/qualification.png'
import img39 from '../ASSETS/training.png'
import img40 from '../ASSETS/education.png'
import img41 from '../ASSETS/promotional-material.png'
import img42 from '../ASSETS/thought-leadership.png'




 

export const cardData1 = [

    {
     
      title: "Strategic Planning & Business Development",
      content: "Assisting in the identification, development, and execution of your company’s strategic goals, ensuring alignment with long-term objectives and market opportunities",
      icon: img1,
    },
    {
      title: "Company Performance Evaluation & KPI Analysis",
      content: "Conducting thorough evaluations of organizational performance, utilizing Key Performance Indicators (KPIs) to measure success and identify areas for improvement",
      icon: img2,
    },
    {
      title: "Startup Advisory & Support",
      content: "Offering tailored support for startups, including guidance on business structure, funding strategies, early-stage business planning, and navigating the unique challenges of launching and growing a new venture",
      icon:img3,
    },
    {
      title: "Feasibility Studies & Business Plan Development",
      content: "Preparing detailed feasibility studies and business plans to assess the viability of new projects, expansions, or business initiatives",
      icon: img4,
    },
    {
      title: "Process Optimization & Operational Efficiency Consulting",
      content: "Analyzing and refining business processes to enhance efficiency, reduce costs, and improve overall productivity",
      icon: img5,
    },
    {
      title: "Risk Management",
      content: "Identifying potential risks and developing strategies to mitigate them, ensuring your business remains resilient and prepared for challenges",
      icon:img6,
    },
    {
      title: "Change Management & Organizational Restructuring",
      content: "Guiding organizations through change initiatives and restructuring processes to adapt to evolving business environments",
      icon: img7,
    },
    {
      title: "Key Improvements & Cost-Cutting Strategies",
      content: "Recommending and implementing key improvements and cost-saving measures to enhance profitability and operational efficiency",
      icon: img8,
    },
    {
      title: "Policy & Procedures Development",
      content: "Crafting and refining company policies and procedures that align with strategic goals and ensure consistent and effective operations across the organization",
      icon:img9,
    },
  ];

  export const cardData2 = [
    {
      title: "Bookkeeping & Financial Reporting",
      content: "Providing accurate and timely financial records, including bookkeeping, preparation of financial statements, and management reports to support informed decision-making",
      icon: img12,
    },
    {
      title: "Budgeting & Forecasting",
      content: "Assisting with the development of budgets and financial forecasts to ensure financial stability and strategic allocation of resources",
      icon: img13,
    },
    {
      title: "Cash Flow Management",
      content: "Implementing strategies to optimize cash flow, ensuring your business has the liquidity needed to meet its obligations and invest in growth opportunities",
      icon: img14,
    },
    {
      title: "Financial Analysis & Performance Review",
      content: "Conducting detailed financial analysis to assess your company’s financial health, identify trends, and recommend actions to improve profitability and efficiency",
      icon: img15,
    },
    {
      title: "Cost Accounting & Profitability Analysis",
      content: "Analyzing costs and profitability across different segments of your business to identify areas for improvement and support strategic pricing decisions",
      icon: img16,
    },
    {
      title: "Internal Controls & Risk Management",
      content: "Designing and implementing internal controls to safeguard assets, ensure accurate financial reporting, and reduce the risk of fraud and errors",
      icon: img17,
    },
    {
      title: "Accounting Systems Implementation & Support",
      content: "Assisting in the selection, implementation, and management of accounting systems that streamline financial processes and improve accuracy",
      icon:img18,
    },
    {
      title: "Audit Preparation & Liaison",
      content: "Preparing your business for audits by external auditors and acting as a liaison during the audit process to ensure a smooth and efficient experience",
      icon: img11,
    },
   
  ];
  export const cardData3 = [
    {
      title: "Talent Acquisition",
      content: "Developing and executing recruitment strategies to attract, hire, and retain top talent that aligns with your company’s culture and objectives",
      icon: img19,
    },
    {
      title: "Local & Overseas Recruitment Services",
      content: "Leveraging our strong relationships with recruitment agencies worldwide,particularly in East Asia and India, to source top-tier candidates for both local and international positions, ensuring a seamless and effective recruitment process",
      icon: img20,
    },
    {
      title: "Performance Management & Employee Development",
      content: "Establishing performance management systems and supporting employee development through regular feedback, evaluations, career pathing and employee wellness program",
      icon: img21,
    },
    {
      title: "Compensation & Benefits Planning",
      content: "Designing competitive compensation and benefits packages that attract and retain top talent while aligning with your company’s financial goals",
      icon: img22,
    },
    {
      title: "HR Policy & Compliance Advisory",
      content: "Developing HR policies that ensure compliance with labor laws and regulations, and providing guidance on managing employee relations and legal issues",
      icon: img23,
    },
    {
      title: "Startup HR Support",
      content: "Offering tailored HR solutions for startups, including the development of HR frameworks, hiring strategies, and employee engagement practices suited to early-stage companies",
      icon: img24,
    },
   
    
   
  ];
  export const cardData4 = [
    {
      title: "Corporate Legal Advisory",
      content: "Providing expert legal advice on corporate governance, contracts, and other business-related legal matters",
      icon: img25,
    },
    {
      title: "Compliance & Regulatory Services",
      content: "Assisting with compliance to local regulations, ensuring your business meets all legal obligations and mitigates risks",
      icon: img26,
    },
    {
      title: "Contract Management & Documentation",
      content: "Drafting, reviewing, and managing contracts and legal documents to protect your business interests and ensure enforceability",
      icon: img27,
    },
    {
      title: "Company Formation & Licensing",
      content: "Guiding businesses through the company formation process, including legal registration, licensing, and compliance with local laws",
      icon: img28,
    },
    {
      title: "Government Relations Services",
      content: "Managing relationships with government agencies and officials to facilitate regulatory approvals, permits, and licenses. We also provide support in navigating governmental processes and advocate on your behalf to ensure compliance and address any regulatory challenges",
      icon: img29,
    },
    {
      title: "Administrative Support & Office Management",
      content: "Offering a range of administrative services, from office management to document control, that enhance operational efficiency and support business growth",
      icon: img30,
    },
    {
      title: "Legal Risk Assessment",
      content: "Conducting legal risk assessments to identify potential issues and develop strategies to mitigate them, ensuring long-term business stability",
      icon: img31,
    },
   
   
  ];
  export const cardData5 = [
    {
      title: "IT Infrastructure & Network Management",
      content: "Designing, implementing, and managing robust IT infrastructures and networks that support your business operations with reliability and scalability",
      icon: img43,
    },
    {
      title: "Cybersecurity Solutions",
      content: "Providing comprehensive cybersecurity services, including risk assessments, vulnerability management, and protection against cyber threats to safeguard your digital assets",
      icon: img32,
    },
    {
      title: "Cloud Computing & Data Management",
      content: "Assisting with the transition to cloud-based systems, optimizing data management, and ensuring secure, scalable, and cost-effective solutions for your business",
      icon: img33,
    },
    {
      title: "Software Development & Integration",
      content: "Offering custom software development, application integration, and system automation solutions tailored to meet your specific business needs",
      icon: img34,
    },
    {
      title: "IT Support & Maintenance",
      content: "Delivering ongoing IT support and maintenance services to ensure your technology systems operate smoothly and efficiently",
      icon: img35,
    },
   
   
  ];
  export const cardData6 = [
   
    {
      title: "Customized Training Programs",
      content: "Developing and delivering bespoke training programs tailored to your organization's specific needs, covering a wide range of topics from technical skills to leadership development",
      icon: img36,
    },
    {
      title: "Training Needs Assessment & Planning",
      content: "Conducting assessments to identify your organization’s training needs and creating strategic training plans to address skill gaps and support business goals",
      icon: img37,
    },
    {
      title: "Professional Certification & Development Courses",
      content: "Offering certification programs and professional development courses to upskill your employees and keep them abreast of industry trends",
      icon: img38,
    },
    {
      title: "Workshops & Seminars",
      content: "Organizing interactive workshops and seminars that focus on critical skills and knowledge areas,promoting continuous learning and development",
      icon: img39,
    },
    {
      title: "E-Learning Solutions & Online Training",
      content: "Providing flexible e-learning platforms and online training modules that allow your team to learn at their own pace and convenience",
      icon: img40,
    },
    {
      title: "Training Materials & Resources Development",
      content: "Creating comprehensive training materials and resources, including manuals, guides, and multimedia content, to support effective learning outcomes",
      icon: img41,
    },
    {
      title: "Leadership & Executive Coaching",
      content: "Offering coaching services for leaders and executives to enhance their leadership skills, decision-making abilities, and overall performance",
      icon: img42,
    },
   
  ];
