import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Box, Typography } from '@mui/material';
import banner from '../ASSETS/beautiful-shot-urban-city-with-modern-architecture-amazing-scenery.jpg';

const CountdownBanner = () => {
  // Set target date for tomorrow at 20:00 PM
 // Set target date for November 16th at 20:00 PM
const targetDate = dayjs().month(10).date(16).hour(20).minute(0).second(0);


  // State to hold the remaining time values
  const [timeLeft, setTimeLeft] = useState({
    days: '00',
    hours: '00',
    minutes: '00',
    seconds: '00',
  });

  useEffect(() => {
    const timer = setInterval(() => {
      const now = dayjs();
      const diff = targetDate.diff(now, 'second');
      if (diff <= 0) {
        clearInterval(timer); // Stop countdown at zero
      } else {
        setTimeLeft({
          days: String(Math.floor(diff / (3600 * 24))).padStart(2, '0'),
          hours: String(Math.floor((diff % (3600 * 24)) / 3600)).padStart(2, '0'),
          minutes: String(Math.floor((diff % 3600) / 60)).padStart(2, '0'),
          seconds: String(diff % 60).padStart(2, '0'),
        });
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [targetDate]);

  return (
    <Box
      sx={{
        width: '100%',
        backgroundImage: `url(${banner})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
        color: 'white',
        py:{xs:5,md: 7,sm:6},
        px: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 4,
        fontWeight: 'bold',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(34, 5, 65, 0.7)', // Dark overlay
          zIndex: 1
        },
      }}
    >
      {/* Countdown Timer Text */}
    {/*   <Typography variant="h4" sx={{ fontWeight: 'bold', zIndex: 2,display:{xs:'none'} }}>
        Webinar starts in
      </Typography> */}

      {/* Centered Countdown Timer */}
      <Box sx={{ display: 'flex', gap:{xs:3,md: 5,sm:4}, zIndex: 2 }}>
        <Box sx={{ textAlign: 'center', display: 'flex', alignItems: 'center', gap: 1 }}>
          <Typography variant="h1" sx={{ fontWeight: 'bold', fontSize:{xs:'1rem',md:'4rem',sm:'2rem' }}}>
            {timeLeft.days}
          </Typography>
          <Typography variant="h6" sx={{ fontSize: {xs:'0.7rem',md:'1.25rem',sm:'1rem'}, color: '#e0e0e0' }}>
            Days
          </Typography>
        </Box>
        <Box sx={{ textAlign: 'center', display: 'flex', alignItems: 'center', gap: 1 }}>
          <Typography variant="h1" sx={{ fontWeight: 'bold', fontSize: {xs:'1rem',md:'4rem',sm:'2rem'  }}}>
            {timeLeft.hours}
          </Typography>
          <Typography variant="h6" sx={{ fontSize: {xs:'0.7rem',md:'1.25rem',sm:'1rem'}, color: '#e0e0e0' }}>
            Hours
          </Typography>
        </Box>
        <Box sx={{ textAlign: 'center', display: 'flex', alignItems: 'center', gap: 1 }}>
          <Typography variant="h1" sx={{ fontWeight: 'bold', fontSize:{xs:'1rem',md:'4rem',sm:'2rem'  }}}>
            {timeLeft.minutes}
          </Typography>
          <Typography variant="h6" sx={{ fontSize: {xs:'0.7rem',md:'1.25rem',sm:'1rem'}, color: '#e0e0e0' }}>
            Minutes
          </Typography>
        </Box>
        <Box sx={{ textAlign: 'center', display: 'flex', alignItems: 'center', gap: 1 }}>
          <Typography variant="h1" sx={{ fontWeight: 'bold', fontSize: {xs:'1rem',md:'4rem',sm:'2rem'  } }}>
            {timeLeft.seconds}
          </Typography>
          <Typography variant="h6" sx={{ fontSize:{xs:'0.7rem',md:'1.25rem',sm:'1rem'}, color: '#e0e0e0' }}>
            Seconds
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default CountdownBanner;
