

import Footer from "../FOOTER/Footer";

import HeaderPages from "../HeaderPages";

import Navbar from "../NAVBAR/Navbar";
import ServiceCard from "../SERVICES/ServiceCard";


import  { cardData1, cardData2 } from "../SERVICES/DataArrays";
import { header2 } from "../SERVICES/HeaderImgs";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { useTitle } from "../SERVICES/TitleContext";
import { useEffect } from "react";
function Service1Page() {
  const { t } = useTranslation();
  const isRTL = i18n.language === 'ar';
  const { title } = useTitle();
  const { setTitle } = useTitle();
  useEffect(() => {
    setTitle("Management & Organization Performance"); // This sets the title when Service1Page mounts
  }, [setTitle]); 
    return (
      <div className="App">
       <Navbar></Navbar>
        
       <HeaderPages headerImg={header2}></HeaderPages>
       <Box sx={{ mt: 16, textAlign:{md:isRTL?'center': 'left',xs:isRTL?'center':'center'},ml:{xs:1,md:16},mr:{xs:isRTL?0:0,md:isRTL?16:0}, }}>
                <Typography 
                    variant="h3" 
                    sx={{ 
                      lineHeight:1.4,
                      fontSize:{xs:'25px',md:'50px'},
                        fontWeight: 'bold', 
                        fontFamily:isRTL?'Noto Kufi Arabic':'Poppins',
                        color: '#fec62a',
                        mb: 4
                    }}>
                      {t(title || "Default Title")}
                   {/* {t("Financial & Accounting Solutions")} */}
                </Typography>
            </Box>
        <ServiceCard cardData={cardData1}
         description={{en:"We optimize your business performance through strategic planning, process improvements, and thorough evaluations, ensuring alignment with goals and sustainable growth.",ar:"تعد خدمة استشارات تقييم الأداء الإداري والتنظيمي أداة قيّمة للمؤسسات الساعية إلى تحسين أدائها وزيادة كفاءتها. وتلبية الاحتياجات الفريدة لكل مؤسسة وذلك من خلال عمل مستشارينا المتخصصون جنبًا إلى جنب مع أصحاب المؤسسات لتحليل الوضع الحالي، وتقييم مؤشرات الأداء، ووضع استراتيجيات فعالة لتحسين الأداء العام. يعد الهدف من هذه الخدمة هو تمكين المؤسسات من تحقيق أهدافها بكفاءة أكبر، وتعزيز قدرتها التنافسية."}} />
         <Footer></Footer>
       
  
      </div>
    );
  }
  
  export default Service1Page;
  