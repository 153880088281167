import React, { createContext, useContext, useState } from 'react';

const TitleContext = createContext();

export const TitleProvider = ({ children }) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  return (
    <TitleContext.Provider value={{ title, setTitle, description, setDescription }}>
      {children}
    </TitleContext.Provider>
  );
};

export const useTitle = () => {
  return useContext(TitleContext);
};
