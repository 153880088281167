import React from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { AccessTime, Event } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import { useNavigate } from 'react-router-dom';
import img1 from '../ASSETS/luay1.png';
import img2 from '../ASSETS/bgweb.png';
import calender from '../ASSETS/calender.png'
import clock from '../ASSETS/clock.png'

const HeaderPages = ({ headerImg }) => {
  const { t } = useTranslation();
  const isRTL = i18n.language === 'ar';
  const navigate = useNavigate();

  return (
    <Box>
      <Box
        sx={{
          position: 'relative',
          height: { md: '850px', xs: 'auto' },
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          color: '#fff',
          '@media (min-width: 900px) and (max-width: 1200px)': {
               height:'auto'},
          marginTop: { md: '-1.2rem', xs: '1.5rem' },
          borderRadius: '0px 0px 0px 0px',
          overflow: 'hidden',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundImage: `url(${img2})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            filter: 'brightness(90%)',
            zIndex: -2,
          },
        }}
      >
        <Grid
          container
          spacing={2}
          columns={16}
          direction={{ xs: 'column', md: 'row' }}
          alignItems={{ xs: 'center', md: 'flex-start', '@media (min-width: 900px) and (max-width: 1200px)': {
            alignItems: 'center',} }} 
        >
          {/* Left Grid for Text Content */}
          <Grid
            item
            xs={16}
            md={8}
            sx={{
              marginTop: { xs: '7rem', md: '15rem' },
              display: 'flex',
              flexDirection: 'column',
              marginLeft: { xs: '0rem', md:isRTL?'2rem': '5rem' },
              justifyContent: { xs: 'center', md: 'flex-start' },
              alignItems: { xs: 'center', md: isRTL ? 'flex-end' : 'flex-start' },
              paddingLeft: { xs: '0', md: isRTL ? '0' : '5rem' },
              paddingRight: { xs: '0', md: isRTL ? '5rem' : '0' },
              textAlign: { xs: 'center', md: isRTL ? 'right' : 'left' },
              zIndex: 2,
              '@media (min-width: 900px) and (max-width: 1200px)': {
                paddingLeft: '1rem',
                display: 'flex',
                paddingRight: '1rem',
                alignItems: 'center',
                flexDirection: 'column',
                textAlign: 'center',
                justifyContent:'center'
              },
            }}
          >
            <Typography
              variant="h4"
              sx={{
                lineHeight: 1.4,
                color: '#fec62a',
                fontWeight: 'bold',
                fontSize: { xs: '16px', md: isRTL? '25px':'25px', sm: '20px' },
                fontFamily: isRTL ? 'Noto Kufi Arabic' : 'Poppins',
                marginBottom: '1rem',
                backgroundColor: '#11111f',
                display: 'inline-block',
                padding: '0.5rem 0.7rem',
                borderRadius: '5px',
              }}
            >
              {t('Online Workshop')}
            </Typography>
            <Typography
              variant="h4"
              sx={{
                lineHeight: isRTL ? 1.1 : 1,
                color: '#11111f',
                fontWeight: 'bold',
                fontSize: { xs: '23px', md:isRTL? '42px':'48px', sm: '35px' },
                fontFamily: isRTL ? 'Noto Kufi Arabic' : 'Poppins',
                marginBottom: { xs: '0.5rem', md: '0.5rem' },
                '@media (min-width: 900px) and (max-width: 1200px)': {
                  fontSize:'35px'
                 },
              }}
            >
              {t('Successful Launch Strategies')}
            </Typography>
            <Typography
              variant="h4"
              sx={{
                lineHeight: isRTL ? 1.3 : 1.2,
                color: '#11111f',
                fontWeight: 'bold',
                fontSize: { xs: '23px', md:isRTL? '42px':'48px', sm: '35px' },
                fontFamily: isRTL ? 'Noto Kufi Arabic' : 'Poppins',
                marginBottom: { xs: '1rem', md: '2rem' },
                '@media (min-width: 900px) and (max-width: 1200px)': {
                  fontSize:'35px'
                 },
              }}
            >
              {t('For Startups Business - From idea to execution')}
            </Typography>
            <Typography
              variant="h4"
              sx={{
                lineHeight: 1.2,
                color: '#11111f',
                fontWeight:isRTL?'500': '200',
                fontSize: { xs: '14px', md:isRTL?'20px': '40px', sm: '20px' },
                fontFamily: isRTL ? 'Noto Kufi Arabic' : 'Cookie',
                marginBottom: { xs: '2rem', md: '4rem' },
                textDecoration: 'underline',
                textDecorationColor: '#fec62a',
                '@media (min-width: 900px) and (max-width: 1200px)': {
                  fontSize:'20px'
                 },
              }}
            >
              {t('Speaker : Louay Abdullah')}
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', textAlign: 'left' }}>
  {/* Display Calendar Icon Conditionally */}
  {!isRTL && (
    <Box
      component="img"
      src={calender}
      alt="Calendar Icon"
      sx={{
        width: { xs: '22px', md: '35px' },
        height: { xs: '22px', md: '35px' },
        marginRight: '0.5rem',
        marginBottom: '1rem',
      }}
    />
  )}
  <Typography
    variant="h4"
    sx={{
      lineHeight: 1.4,
      fontFamily: isRTL ? 'Noto Kufi Arabic' : 'Poppins',
      color: '#11111f',
      fontWeight: 'bold',
      fontSize: { xs: '12px', md:isRTL? '20px':'22px', sm: '20px' },
      marginBottom: '1.2rem',
      marginRight: isRTL ? '1rem' : '0',
      '@media (min-width: 900px) and (max-width: 1200px)': {
        fontSize: '17px',
      },
    }}
  >
    {t('Saturday, 16 November 2024')}
  </Typography>
  {isRTL && (
    <Box
      component="img"
      src={calender}
      alt="Calendar Icon"
      sx={{
        width: { xs: '22px', md: '35px' },
        height: { xs: '22px', md: '35px' },
        marginRight: '0.5rem',
        marginBottom: '1rem',
      }}
    />
  )}
</Box>
<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', textAlign: 'left' }}>
{!isRTL && (
    <Box
      component="img"
      src={clock}
      alt="clock Icon"
      sx={{
        width: { xs: '22px', md: '35px' },
        height: { xs: '22px', md: '35px' },
        marginRight: '0.5rem',
        marginBottom: '1rem',
      }}
    />
  )}
  <Typography
    variant="h4"
    sx={{
      lineHeight: 1.4,
      fontFamily: isRTL ? 'Noto Kufi Arabic' : 'Poppins',
      color: '#11111f',
      fontWeight: 'bold',
      fontSize: { xs: '12px', md:isRTL? '20px':'22px', sm: '20px' },
      marginBottom: '1.2rem',
      marginRight: isRTL ? '1rem' : '0',
      '@media (min-width: 900px) and (max-width: 1200px)': {
        fontSize: '17px',
      },
    }}
  >
    {t('At 20.00 pm KSA')}
  </Typography>
  {isRTL && (
    <Box
      component="img"
      src={clock}
      alt="Calendar Icon"
      sx={{
        width: { xs: '22px', md: '35px' },
        height: { xs: '22px', md: '35px' },
        marginRight: '0.5rem',
        marginBottom: '1rem',
      }}
    />
  )}
</Box>


            <Box sx={{ textAlign: 'center', mt: { xs: '1rem', md: '1rem' } }}>
              <Button
                onClick={() => navigate('/form')}
                sx={{
                  color: '#11111f',
                  fontWeight: 'bold',
                  fontSize: { xs: '14px', md:isRTL? '25px':'22px', sm: '20px' },
                  fontFamily: isRTL ? 'Noto Kufi Arabic' : 'Poppins',
                  backgroundColor: '#fec62a',
                  borderRadius: '10px',
                  padding: '0.5rem 1.5rem',
                  border: '1px solid #11111f',
                  '@media (min-width: 900px) and (max-width: 1200px)': {
                  fontSize:'20px'
                 },
                }}
              >
                {t('Register Now')}
              </Button>
            </Box>
          </Grid>

          {/* Right Grid for Image */}
          <Grid
            item
            xs={16}
            md={8}
            sx={{
              display: 'flex',
              alignItems: { xs: 'right', md: 'flex-end' },
              justifyContent: { xs: 'right', md: 'flex-end' },
              mt: { xs: '0rem', md: '0' },
              zIndex: 1,
             
              
            }}
          >
            <Box
              component="img"
              src={img1}
              alt="Speaker"
              sx={{
                width: { xs: '100%', md: '100%' },
                maxWidth: { xs: '370px', md: '680px', sm: '600px' },
                position: { md: 'absolute' },
                bottom: { xs: 0, md: 0 },
                right: { xs: 0, md: 0 },
                marginRight: { xs: '0rem', md: '0' },
                '@media (min-width: 900px) and (max-width: 1200px)': {
                 maxWidth:'550px'
                },
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default HeaderPages;
